export const imageURI = {
  stopp: require("../../assets/skills/icon-stoppdecider.png"),
  "it-will-pass": require("../../assets/skills/icon-it-will-passdecider.png"),
  distract: require("../../assets/skills/icon-distractdecider.png"),
  "do-one-thing": require("../../assets/skills/icon-do-one-thingdecider.png"),
  "turn-the-mind": require("../../assets/skills/icon-turn-the-minddecider.png"),
  "right-now": require("../../assets/skills/icon-right-nowdecider.png"),
  soothe: require("../../assets/skills/icon-soothedecider.png"),
  values: require("../../assets/skills/icon-valuesdecider.png"),
  "self-care": require("../../assets/skills/icon-self-caredecider.png"),
  thinking: require("../../assets/skills/icon-thinkingdecider.png"),
  "name-the-emotion": require("../../assets/skills/icon-name-the-emotiondecider.png"),
  "opposite-action": require("../../assets/skills/icon-opposite-actiondecider.png"),
  "sieve-or-sponge": require("../../assets/skills/icon-sieve-or-spongedecider.png"),
  "build-positive-experiences": require("../../assets/skills/icon-build-positive-experiencesdecider.png"),
  balance: require("../../assets/skills/icon-balancedecider.png"),
  "pace-and-plan": require("../../assets/skills/icon-pace-and-plandecider.png"),
  breathe: require("../../assets/skills/icon-breathedecider.png"),
  observe: require("../../assets/skills/icon-observedecider.png"),
  "this-moment": require("../../assets/skills/icon-this-momentdecider.png"),
  focus: require("../../assets/skills/icon-focusdecider.png"),
  drift: require("../../assets/skills/icon-driftdecider.png"),
  "fact-or-opinion": require("../../assets/skills/icon-fact-or-opiniondecider.png"),
  "wise-mind": require("../../assets/skills/icon-wise-minddecider.png"),
  "keep-calm": require("../../assets/skills/icon-keep-calmdecider.png"),
  listen: require("../../assets/skills/icon-listendecider.png"),
  respect: require("../../assets/skills/icon-respectdecider.png"),
  reflect: require("../../assets/skills/icon-reflectdecider.png"),
  "crystal-clear": require("../../assets/skills/icon-crystal-cleardecider.png"),
  "ask-or-say-no": require("../../assets/skills/icon-ask-or-say-nodecider.png"),
  "maintain-relationship": require("../../assets/skills/icon-maintain-relationshipdecider.png"),
  "talk-the-talk": require("../../assets/skills/icon-talk-the-talkdecider.png"),
  "end-or-mend": require("../../assets/skills/icon-end-or-menddecider.png"),
};
